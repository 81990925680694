import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import DownloadProp from '../components/DownloadProp';

const DownloadPage = () => (
    <Layout>
        <SEO title="Descargar - Aguaman" description="" pathname="/descargar" />
        <DownloadProp />
    </Layout>
);

export default DownloadPage;
